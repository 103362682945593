<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img :src="require('@/assets/images/logo/interactvty-logo.png')" fluid style="height: 80px" alt="placeholder" />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('autorizado') }} 🔐
        </h2>
        <p class="mb-2">
          {{ $t('permision') }}
        </p>
        <router-link to="/dashboard">
          <b-button variant="primary" class="mb-1 btn-sm-block">
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </router-link>

        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BImg, BButton } from 'bootstrap-vue'
import store from '@/store/index'

const notAuthor = require('@/assets/images/pages/not-authorized.svg')
const notAuthorDark = require('@/assets/images/pages/not-authorized-dark.svg')

export default {
  components: {
    BLink,
    BImg,
    BButton,
  },
  data() {
    return {
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return notAuthorDark
      }
      return notAuthor
    },
  },
  methods: {

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
